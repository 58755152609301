import { DotLoader } from "react-spinners";

// Can be a string as well. Need to ensure each key-value pair ends with ;

function App({ loading }) {
  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            width: "100vw",
            zIndex: "10",
            background: "rgb(191, 191, 191, 0.1)",
          }}
        >
          <DotLoader color={"#3f51b5"} loading={loading} size={150} />
        </div>
      ) : null}
    </>
  );
}

export default App;
//#3f51b5
//#f50057
