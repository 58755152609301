import React, { useState, useEffect } from "react";
import "./UD2_projectEarnings.css";
import { Paper } from "@material-ui/core";
import { useAuth } from "../../../AuthContext";
import firebase from "../../../firebase";

const UD2_projectEarnings = () => {
  const { currentUser } = useAuth();
  const [earnings, setEarnings] = useState(0);
  useEffect(() => {
    console.log("this is working");
    if (currentUser) {
      firebase
        .database()
        .ref("users/" + currentUser.uid)
        .get()
        .then((snapshot) => {
          setEarnings(snapshot.val()?.earnings ? snapshot.val().earnings : 0);
        });
    }
  }, [currentUser]);
  return (
    <>
      <h2
        style={{ marginBottom: "15px", justifySelf: "flex-start" }}
        variant="h6"
        color="primary"
      >
        Total Earnings
      </h2>
      <Paper
        elevation={3}
        style={{ backgroundColor: "#f8f8f8" }}
        className="ud2--paper"
      >
        <Paper
          style={{ backgroundColor: "lightgoldenrodyellow" }}
          className="ud2--earningsCard"
        >
          <h1>${earnings}</h1>
        </Paper>
      </Paper>
    </>
  );
};
export default UD2_projectEarnings;
